/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "wrapper": "px-6",
  "caption_colors_primary": "bg-black text-white",
  "caption_size_default": "text-gabor-sm md:text-gabor-md px-3 md:px-4 py-2 w-fit max-w-lg md:mx-auto mb-1.5 md:mb-0",
  "caption_variant_default": "md:text-center",
  "description_colors_primary": "bg-white",
  "description_size_default": "text-headline-sm md:text-headline-md px-3 py-2 w-fit max-w-[774px] md:mx-auto",
  "description_variant_default": "md:text-center",
  "group_colors_primary": "",
  "group_size_default": "w-full px-6",
  "group_variant_default": "absolute bottom-6 md:bottom-5",
  "image_colors_primary": "",
  "image_size_default": "aspect-[0.78125/1] md:aspect-[2.27142857/1]",
  "image_variant_default": "",
  "video_colors_primary": "",
  "video_size_default": "aspect-[0.78125/1] md:aspect-[2.27142857/1]",
  "video_variant_default": "object-cover",
  "logo_colors_primary": "",
  "logo_size_default": "[&_svg]:w-31 [&_svg]:h-11 md:[&_svg]:w-36 md:[&_svg]:h-11.5",
  "logo_variant_default": "absolute top-6 left-6 md:top-7 md:left-7"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "FrontCoverstory");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;