import { tw } from '@/utils/tw';
import BaseRowTheme from 'base/components/Row/theme';

const { Button } = BaseRowTheme;

const Row = tw.theme({
  extend: BaseRowTheme,
  slots: {
    base: ``,
    headline: `text-gabor-sm sm:text-gabor-md -mb-3 font-secondary uppercase`,
    caption: `text-paragraph-md`,
    footer: `[&:not(:first-child)]:mt-6.5`,
  },
  variants: {
    size: {
      primary: {
        base: `gap-y-6 md:gap-y-6 md:self-start md:container-col-md-12:gap-x-4 lg:container-col-lg-12:gap-x-6`,
      },
    },
  },
});

export default Object.assign(Row, { Button });
